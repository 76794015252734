<template>
  <div v-if="strapiData?.length > 0" class="block-brands-list">
    <AtomHeading
      v-if="data.title"
      tag="h3"
      appereance="h3"
      :text="data.title"
    />

    <div class="block-brands-list__inner">
      <BlockBrandsListItem
        v-for="brand in strapiData"
        :key="brand.id"
        :link="brand?.sb_slug"
        :logo="brand?.hero_logo"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

/*
      Setup
*/
const context = useContextStore();
const route = useRoute();
const { find } = useStrapi4();

const {
    strapiBrandId,
    strapiManufacturerId,
} = storeToRefs(context);

/*
      Get Items
*/
const brandIds = computed(() => {
    if (props.data.brands?.length > 0) {
        return props.data.brands.map((id) => parseInt(id, 10));
    }

    return strapiBrandId.value || [];
});

const manufacturerId = computed(() => {
    if (props.data.manufacturers?.length > 0) {
        return props.data.manufacturers.map((id) => parseInt(id, 10));
    }

    return strapiManufacturerId.value || [];
});

const loadAllFilter = {
    sb_slug: {
        $notNull: true,
    },
    hero_logo: {
        id: {
            $notNull: true,
        },
    },
};

const { data: strapiData } = useLazyAsyncData(`brands/${getUid()}`, () => {
    let brandsFilter = null;
    let manufacturerFilters = null;

    if (props.data.load_all) {
        brandsFilter = loadAllFilter;
        manufacturerFilters = loadAllFilter;
    } else {
        if (brandIds.value?.length > 0) {
            brandsFilter = {
                $and: [
                    {
                        id: {
                            $in: brandIds.value,
                        },
                    },
                    {
                        sb_slug: {
                            $notNull: true,
                        },
                    },
                    {
                        hero_logo: {
                            id: {
                                $notNull: true,
                            },
                        },
                    },
                ],
            };
        }

        if (manufacturerId.value?.length > 0) {
            manufacturerFilters = {
                $and: [
                    {
                        id: {
                            $in: manufacturerId.value,
                        },
                    },
                    {
                        sb_slug: {
                            $notNull: true,
                        },
                    },
                    {
                        hero_logo: {
                            id: {
                                $notNull: true,
                            },
                        },
                    },
                ],
            };
        }
    }

    const promises = [];

    if (brandsFilter) {
        promises.push(find('brands', {
            filters: brandsFilter,
            fields: ['sb_slug'],
            populate: {
                hero_logo: true,
            },
        }));
    }

    if (manufacturerFilters) {
        promises.push(find('manufacturers', {
            filters: manufacturerFilters,
            fields: ['sb_slug'],
            populate: {
                hero_logo: true,
            },
        }));
    }

    const result = Promise.all(promises);
    return result;
}, {
    transform: (res) => {
        if (!res || !res.length) {
            return [];
        }

        const merged = [];
        res.forEach((r) => {
            merged.push(...r.data);
        });

        const sorted = merged?.sort(
            (a, b) => {
                if (a.attributes?.sb_slug && b.attributes?.sb_slug) {
                    return a.attributes?.sb_slug?.localeCompare(b.attributes?.sb_slug);
                }

                if (a.sb_slug && b.sb_slug) {
                    return a?.sb_slug.localeCompare(b.sb_slug);
                }

                return brandsManufacturers;
            },
        );

        const filtered = sorted.filter((entry) => !route.path.includes(entry.attributes.sb_slug));

        return filtered.map((entry) => {
            const result = entry.attributes;
            result.hero_logo = result.hero_logo?.data?.attributes;
            return result;
        });
    },
});
</script>

<style lang="scss">
.block-brands-list {
    @include component-spacing(30px, 60px);
    @include top-level-only {
        grid-column: 1 / -1;
    }

    .atom-heading {
        @include fluid('margin-bottom', 10px, 20px);
    }

    & + .atom-heading {
        @include fluid('margin-top', 11px, 31px);
    }
}

.block-brands-list__inner {
    @include grid-layout(6, 1fr);
    @include fluid('grid-gap', 10px, 34px);

    @include tablet {
        @include grid-layout(3, 1fr);
    }

    @include mobile {
        @include grid-layout(2, 1fr);
    }
}
</style>
